<template>
  <div v-if="processed">
    <div style="margin-top:100px;width:100%;">
      <img
        src="./../assets/logo.png"
        class="imgWidth"
        style="display: block;margin: 0px auto;padding: 10px;border-radius: 5px;max-width: 300px;"
      >
    </div>
    <div
      v-if="success"
      class="primary-colour text-center largerFont"
      style="font-size:1rem;text-align:center;margin-top:20px;"
    >
      You have been unsubscribed from receiving future reminder emails.
    </div>
    <div
      v-else
      class="primary-colour text-center largerFont"
      style="font-size:1rem;text-align:center;margin-top:20px;"
    >
      Sorry, we've been unable to unsubscribe you.
    </div>
    <div class="primary-colour text-center largerFont" style="font-size:1rem;text-align:center;margin-top:30px;">
      <mdb-btn
        style="width:120px;font-size:0.8rem;"
        class="btn primary-btn btn-radius btn-sm btn-md m-0 ml-0"
        @click="goHome()"
      >
        CONTINUE
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import userService from '@/api-services/user.service'

export default {
  name: 'UNSUBSCRIBE',
  data () {
    return {
      processed: false,
      success: false
    }
  },
  created () {
    this.unsubscribe(this.$route.query.uid)
  },
  methods: {
    unsubscribe (id) {
      userService.unsubscribe(id).then((res) => {
        if (res.data) {
          this.processed = true
          this.success = true
        } else {
          this.processed = true
          this.success = false
        }
      }).catch(() => {
        this.processed = true
      })
    },
    goHome () {
      window.location.href = '/'
    }
  }
}
</script>
